<template>
  <div>
    <div class="vx-row pb-10">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        class=""
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl font-bold fontSizeMedium">
        {{ $t("YourAllAppointmentConsulataion") }}
      </p>
    </div>
    <div>
      <vx-card style="backgroun-color: none !important">
        <div slot="no-body" class="tabs-container pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab
              :label="$t('UpCommingDates') + '  (' + CommingCount + ')'"
              icon-pack="feather"
              icon="icon-corner-down-right"
              @click="SearchAppointment(true)"
              @RefershGrid="SearchAppointment"
            >
              <div class="tab-text">
                <Reservation-Sessions class="mt-4"  @refreshDataAfterCanceletion="refreshDataAfterCanceletion()" :Model="Model"  />
              </div>
            </vs-tab>
            <vs-tab
              :label="$t('OldDates') + '  (' + OldCount + ')'"
              icon-pack="feather"
              icon="icon-corner-down-left"
              @click="SearchAppointment(false)"
            >
              <div class="tab-text">
                <Reservation-Sessions class="mt-4"  :Model="Model" />
              </div>
            </vs-tab>
            <vs-tab
              :label="$t('cancelledBookings') + '  (' + canncelCount + ')'"
              icon-pack="feather"
              icon="icon-corner-down-left"
            >
              <div class="tab-text">
                <Reservation-Sessions   class="mt-4" :Model="cancelModel" />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";

import { domain } from "@/gloabelConstant.js";
import ReservationSessions from "@/views/Patient/Reservation/ReservationSession.vue";
export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
      cancelModel: [],
      activeTab: 0,
      EnglishLan: false,
      OldCount: 0,
      CommingCount: 0,
      canncelCount: 0,
    };
  },
  components: {
    ReservationSessions,
  },
  props: {

  },
  methods: {
    back() {
      debugger;
      this.$router.push("/patient/home");
    },
    SearchAppointment(isComming) {
      debugger;
      var search = {
        patientID: this.$store.state.AppActiveUser.Patient.ID,
        IsNext: false,
        PatientJoin: true,
      };
      this.$store
        .dispatch("patientList/SearchPatientReservationSessions", search)
        .then((res) => {
          debugger;
          if (res.status == 200)
          {

            this.OldCount = res.data.Data.length;
          }
        });

      if (isComming == true )
       {search.IsNext = true;search.PatientJoin=false ; search.DateFrom = new Date() }
      else
      {
        debugger;
        search.IsNext = false;
       search.PatientJoin=true;
      }

      this.$store
        .dispatch("patientList/SearchPatientReservationSessions", search)
        .then((res) => {
          if (res.status == 200) {
            this.Model = res.data.Data;
            if (this.Model == null || this.Model.length == 0) {
              // this.$vs.notify({
              //   title: this.$t("NoData"),
              //   text: this.$t("NoDataToshow"),
              //   iconPack: "feather",
              //   icon: "icon-alert-circle",
              //   color: "warning",
              // });
            }
          }
          debugger
          if (isComming == true) this.CommingCount = this.Model.length;
        })
        .catch(() => {
          window.showError();
        });
      this.SearchCancellAppointment();
    },
    refreshDataAfterCanceletion(){
      debugger
        this.SearchCancellAppointment();
        this.SearchAppointment(true);

    },
    SearchCancellAppointment() {
      debugger;
      var search = {
        patientID: this.$store.state.AppActiveUser.Patient.ID,
        StatusID: 3,
      };
      debugger;
      this.$store
        .dispatch(
          "patientList/SearchPatientCancelledReservationSessions",
          search
        )
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.cancelModel = res.data.Data;
            if (this.cancelModel == null || this.cancelModel.length == 0) {
              // this.$vs.notify({
              //   title: this.$t("NoData"),
              //   text: this.$t("NoDataToshow"),
              //   iconPack: "feather",
              //   icon: "icon-alert-circle",
              //   color: "warning",
              // });
            }
          }
          this.canncelCount = this.cancelModel.length;
          debugger;
        })
        .catch(() => {
          window.showError();
        });
    },
  },
  created() {
    debugger;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.SearchAppointment(true);
  },
  mounted()
  {
      window.onscroll = () => {
      myFunction()

    };

      function myFunction() {
           var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
        }
  }
};
</script>
